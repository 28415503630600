<template>
  <v-card class="card-shadow" v-if="showInfo">
    <v-card-text>
      <v-row>
        <v-col self-align="center">
          <label class="text-md text-grt font-weight-bolder mx-2">
            委託人
          </label>
        </v-col></v-row
      >
      <v-row class="mx-2" v-for="(client, c_ind) in clients" :key="c_ind">
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">姓名</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="client.name"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">電話</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="client.phone"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">手機</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="client.mobile"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">身份證</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="client.id_full"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="3" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">聯絡地址</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="client.address"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="1" cols="12" class="py-0" align-self="end" v-if="!readOnly">
          <v-btn icon v-if="c_ind == clients.length - 1" @click="addClient">
            <v-icon color="primary">mdi-plus-circle</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="client.id == null && clients.length > 1"
            @click="removeClient(c_ind)"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <label class="text-md text-grt font-weight-bolder mx-2">
            地主/屋主
          </label>
        </v-col>
        <v-col class="py-0">
          <v-radio-group
            row
            v-model="local_principals.same_owner"
            @change="checkOwner()"
            :disabled="propEditMode == edit_mode_enum.View"
          >
            <v-radio
              v-for="(item, i) in same_principal_name_str"
              :key="item + i"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="mx-2" v-for="(owner, o_ind) in owners" :key="o_ind + 1000">
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">姓名</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="owner.name"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">電話</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="owner.phone"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">手機</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="owner.mobile"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">身份證</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="owner.id_full"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="3" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">聯絡地址</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="owner.address"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="1" cols="12" class="py-0" align-self="end" v-if="!readOnly">
          <v-btn icon v-if="o_ind == owners.length - 1" @click="addOwner">
            <v-icon color="primary">mdi-plus-circle</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="owner.id == null && owners.length > 1"
            @click="removeOwner(o_ind)"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <label class="text-md text-grt font-weight-bolder mx-2"> 中人 </label>
        </v-col></v-row
      >
      <v-row class="mx-2" v-for="(agent, a_ind) in agents" :key="a_ind + 2000">
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">姓名</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.name"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">電話</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.phone"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">手機</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.mobile"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">身份證</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.id_full"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="3" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">聯絡地址</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.address"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="1" cols="12" class="py-0" align-self="end" v-if="!readOnly">
          <v-btn icon v-if="a_ind == agents.length - 1" @click="addAgent">
            <v-icon color="primary">mdi-plus-circle</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="agent.id == null && agents.length > 1"
            @click="removeAgent(a_ind)"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-2">
        <v-col md="2" cols="12">
          <label class="font-weight-bolder ms-1">委託底價</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="local_principals.lowest_price"
            type="number"
            suffix="萬"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12">
          <label class="font-weight-bolder ms-1">服務費</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="local_principals.service_fee"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="4" cols="12">
          <label class="font-weight-bolder ms-1">中人佔佣</label>
          <v-row class="ml-2 mt-0 my-0">
            <v-radio-group
              row
              v-model="local_principals.has_commission"
              @change="checkCommission(local_principals)"
              :disabled="readOnly"
            >
              <v-radio
                v-for="(item, i) in commission_enum_str"
                :key="item + i"
                :label="item.text"
                :value="item.value"
              ></v-radio>
            </v-radio-group>
            <v-btn
              class="my-3"
              icon
              @click="
                local_principals.has_commission = null;
                local_principals.commission_ratio = null;
              "
              :disabled="readOnly"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-col cols="7" class="py-0">
              <v-text-field
                hide-details
                outlined
                :class="text_edit_class"
                v-model="local_principals.commission_ratio"
                :disabled="
                  !local_principals.has_commission ||
                  propEditMode == edit_mode_enum.View
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-card-text class="text-center">
      <v-form>
        <v-row justify="center">
          <v-col cols="6" self-align="center">
            <label class="font-weight-bolder ms-1">請輸入密碼</label>
            <v-text-field
              autocomplete="new-password"
              v-model="unlock_password"
              hide-details
              outlined
              light
              placeholder="解鎖密碼"
              class="
                font-size-input
                placeholder-lighter
                border border-radius-md
                mt-2
              "
              :append-icon="show_unlock_password ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="show_unlock_password = !show_unlock_password"
              :type="show_unlock_password ? 'text' : 'password'"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        class="
          font-weight-bold
          text-xs
          btn-default
          bg-gradient-primary
          px-6
          py-2
          mt-2 mt-8
        "
        @click="unlockPanel()"
      >
        確認
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  case_mode_enum,
  commission_enum_str,
  same_principal_name_str,
  related_person_type_enum,
} from "@/case.js";
import { edit_mode_enum, common_text_edit_class } from "@/definitions.js";
export default {
  name: "case-principals",
  props: {
    propInfo: {
      type: Object,
      default: () => {},
    },
    propRelatedPeople: {
      type: Array,
      default: () => [],
    },
    propEditMode: {
      type: Number,
    },
    propCaseMode: {
      type: Number,
    },
    propReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      edit_mode_enum: edit_mode_enum,
      commission_enum_str: commission_enum_str,
      same_principal_name_str: same_principal_name_str,
      related_person_type_enum: related_person_type_enum,
      local_principals: {},
      unlock_password: null,
      show_unlock_password: false,
      unlock: false,
      text_edit_class: common_text_edit_class,
      case_mode_enum: case_mode_enum,
      init: false,
      clients: [],
      owners: [],
      agents: [],
    };
  },
  mounted() {
    this.parseRelatedInfo(this.propInfo, this.propRelatedPeople);
  },

  computed: {
    showInfo() {
      return this.isVerifyPassword() || this.unlock;
    },
    readOnly() {
      return this.propEditMode == edit_mode_enum.View || this.propReadOnly;
    },
  },
  watch: {
    clients: {
      deep: true,
      handler(val) {
        this.checkOwner();
      },
    },
  },
  methods: {
    ...mapGetters(["isSkipPrincipalVerify", "getToken"]),
    isVerifyPassword() {
      // console.log("isVerifyPassword", this.propEditMode);
      return (
        this.isSkipPrincipalVerify() ||
        this.propCaseMode == case_mode_enum.Draft
      );
    },
    checkInfo(info, type) {
      // see
      if (info != null && info.length == 0) {
        info.push({
          id: null,
          type: type,
          name: null,
          phone: null,
          mobile: null,
          address: null,
        });
      }
      return info;
    },
    parseRelatedInfo(val, related_people) {
      console.log("principals", val, related_people);
      if (this.init == true) {
        return;
      }
      this.init = true;
      this.local_principals = _.cloneDeep(val);
      if (this.local_principals == null) {
        this.local_principals = {
          lowest_price: null,
          service_fee: null,
          has_commission: null,
          commission_ratio: null,
          same_owner: false,
        };
      }
      // filter the type = 0 and assign to clients
      if (related_people == null) {
        related_people = [];
      }
      this.clients = related_people.filter(
        (x) => x.type == related_person_type_enum.Client
      );
      // filter the type = 1 and assign to owners
      this.owners = related_people.filter(
        (x) => x.type == related_person_type_enum.Owner
      );
      // filter the type = 2 and assign to agents
      this.agents = related_people.filter(
        (x) => x.type == related_person_type_enum.Agent
      );
      this.checkInfo(this.clients, related_person_type_enum.Client);
      this.checkInfo(this.owners, related_person_type_enum.Owner);
      this.checkInfo(this.agents, related_person_type_enum.Agent);
    },
    addPrincipal() {},
    removePrincipal(index) {},
    getInfo() {
      let info = _.cloneDeep(this.local_principals);
      return info;
    },
    getLegalInfo(info) {
      // filter the name is not null or empty in info array
      return info.filter((item) => item.name != null && item.name != "");
    },
    getRelatedPeople() {
      let related_people = [];
      // merge the clients, owners, agents
      related_people = related_people.concat(this.getLegalInfo(this.clients));
      related_people = related_people.concat(this.getLegalInfo(this.owners));
      related_people = related_people.concat(this.getLegalInfo(this.agents));
      return related_people;
    },
    checkCommission(item) {
      const has_commission = item.has_commission;
      console.log("checkCommission", has_commission);
      if (has_commission == false) {
        item.commission_ratio = null;
      }
    },
    checkOwner() {
      if (this.local_principals.same_owner == true) {
        this.setOwnersFromClient();
      }
    },
    setOwnersFromClient() {
      this.owners = [];
      this.clients.forEach((client) => {
        let tmp = _.cloneDeep(client);
        tmp.type = related_person_type_enum.Owner;
        this.owners.push(tmp);
      });
    },
    addClient() {
      this.clients.push({
        id: null,
        type: related_person_type_enum.Client,
        name: null,
        phone: null,
        mobile: null,
        address: null,
        id_full: null,
      });
    },
    removeClient(index) {
      if (this.clients.length == 1) {
        return;
      }
      this.clients.splice(index, 1);
    },
    addOwner() {
      this.owners.push({
        id: null,
        type: related_person_type_enum.Owner,
        name: null,
        phone: null,
        mobile: null,
        address: null,
        id_full: null,
      });
    },
    removeOwner(index) {
      if (this.owners.length == 1) {
        return;
      }
      this.owners.splice(index, 1);
    },
    addAgent() {
      this.agents.push({
        id: null,
        type: related_person_type_enum.Agent,
        name: null,
        phone: null,
        mobile: null,
        address: null,
        id_full: null,
      });
    },
    removeAgent(index) {
      if (this.agents.length == 1) {
        return;
      }
      this.agents.splice(index, 1);
    },

    clear() {
      console.log("clear principals");
      this.local_principals = [];
      this.unlock = false;
      this.unlock_password = null;
      this.show_unlock_password = false;
      this.clients = [];
      this.owners = [];
      this.agents = [];
      this.init = false;
    },
    unlockPanel() {
      if (this.unlock_password == null) {
        alert("請輸入密碼");
        return;
      }
      let url = `${process.env.VUE_APP_SERVER_URL}/api/v1/users/verify-principals`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.getToken(),
        },
      };
      let data = {
        password: this.unlock_password,
      };
      const currentObj = this;

      this.axios
        .put(url, data, config)
        .then(function (response) {
          console.log(response.data);
          console.log("update done");
          currentObj.unlock = true;
        })
        .catch(function (error) {
          console.log(error.response);
          if (error.response.data && error.response.data.detail) {
            if (
              error.response.data.detail.includes("Incorrect email or password")
            ) {
              alert("密碼錯誤");
            }
          }
        });
    },
  },
};
</script>
