<template>
  <div>
    <customer-detail-dialog
      :propEditMode="edit_mode"
      :propEditItem="edited_item"
      :propDisplay="display_detail"
      v-on:close="close"
      v-on:create_customer="onCreateCustomer"
      v-on:update_customer="onUpdateCustomer"
      v-on:refresh="getCustomers"
    ></customer-detail-dialog>
    <common-loading
      :title="loading_dialog.title"
      :message="loading_dialog.message"
      :loading_dialog="loading_dialog.dialog"
      :show_loading="loading_dialog.show_loading"
      :show_confirm="loading_dialog.show_confirm"
      :confirm_function="loading_dialog.confirm_function"
      v-on:confirm="loading_dialog.dialog = false"
    >
    </common-loading>
    <!-- review dialog -->
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">客戶資料</h5>
      </div>
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :options.sync="options"
          :items="items"
          :server-items-length="items_count"
          :loading="loading"
          hide-default-footer
          disable-pagination
          v-model="selected"
          :show-select="selectable"
        >
          <!-- 自定義選擇框列 -->
          <!-- 自定义选择框列 -->
          <template v-slot:item.data-table-select="{ item, isSelected }">
            <div class="v-data-table__checkbox">
              <v-simple-checkbox
                v-if="item.data_source === '指定'"
                :value="isSelected"
                @input="
                  $set(item, 'selected', $event);
                  updateSelected(item, $event);
                "
                :ripple="false"
              ></v-simple-checkbox>
              <span v-else></span>
            </div>
          </template>
          <template v-slot:loading>
            {{ $t("Loading...") }}
          </template>
          <template v-slot:top>
            <slot name="header"> </slot>
            <v-row>
              <v-col cols="12" lg="6" class="ml-auto d-flex justify-end">
                <v-pagination
                  prev-icon="fa fa-angle-left"
                  next-icon="fa fa-angle-right"
                  class="pagination"
                  color="#cb0c9f"
                  v-model="page"
                  :length="getPageCount()"
                  circle
                  :total-visible="15"
                ></v-pagination>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.full_name="{ item }">
            {{ item.full_name }}
          </template>
          <template v-slot:item.id_full="{ item }">
            {{ getID(item) }}
          </template>
          <template v-slot:item.address_1="{ item }">
            {{ getAddress_1(item) }}
          </template>
          <template v-slot:item.address_2="{ item }">
            {{ getAddress_2(item) }}
          </template>
          <template v-slot:item.home="{ item }">
            {{ getPhoneByType(item, phone_type_enum.Home) }}
          </template>
          <template v-slot:item.mobile="{ item }">
            {{ getPhoneByType(item, phone_type_enum.Mobile) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom v-if="isAllowUpdateCustomer()">
              <template v-slot:activator="{ on }">
                <v-icon large class="mr-2" v-on="on" @click.stop="edit(item)"
                  >mdi-application-edit-outline</v-icon
                >
              </template>
              <span>{{ $t("Edit") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-icon large class="mr-2" v-on="on" @click.stop="view(item)"
                  >mdi-account-search-outline</v-icon
                >
              </template>
              <span>{{ $t("View") }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="12" lg="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#cb0c9f"
              v-model="page"
              :length="getPageCount()"
              circle
              :total-visible="15"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { phone_type_enum, edit_mode_enum } from "@/definitions.js";
import { defaultCustomer } from "@/customers.js";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);
import { mapGetters } from "vuex";
import CustomerBase from "./Widgets/CustomerBase.vue";
import CustomerDetailDialog from "./CustomerDetailDialog.vue";
import CommonLoading from "@/views/Pages/General/Widgets/CommonLoading.vue";
import CommonConfirm from "@/views/Pages/General/Widgets/CommonConfirm.vue";
import _ from "lodash";
export default {
  name: "customer-table-base",
  props: {
    auto_search: {
      type: Boolean,
      default: true,
    },
    searchInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    propagate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    phone_type_enum: phone_type_enum,
    edit_mode_enum: edit_mode_enum,
    edit_mode: edit_mode_enum.View,
    display_detail: false,
    defaultCustomer: defaultCustomer,
    edited_item: {},
    edit_dialog: false,
    selected: [],
    loading_dialog: {
      dialog: false,
      title: null,
      message: null,
      show_loading: true,
      show_confirm: false,
      confirm_function: null,
    },
    // confirm: {
    //   dialog: false,
    //   title: null,
    //   message: null,
    //   function: null,
    // },
  }),
  mixins: [CustomerBase],
  components: {
    CustomerDetailDialog,
    CommonLoading,
    CommonConfirm,
  },
  mounted() {
    if (this.auto_search) {
      this.getCustomers();
    }
  },
  methods: {
    ...mapGetters(["getToken", "isAllowUpdateCustomer"]),
    reset() {
      this.page = 1;
      this.start_date = null;
      this.end_date = null;
      this.items_count = 0;
      this.items = [];
      this.searchInfoBase = {
        full_name: null,
        id_full: null,
        company: null,
        address: null,
        phone: null,
        data_source: null,
        group: null,
      };
      if (this.auto_search) {
        this.getCustomers();
      }
    },
    onOptionChange() {
      this.page = 1;
      this.getCustomers();
    },
    addCustomer() {
      this.edited_item = _.cloneDeep(this.defaultCustomer);
      this.edit_mode = this.edit_mode_enum.Create;
      this.display_detail = true;
    },
    edit(item) {
      this.edited_item = _.cloneDeep(item);
      this.edit_mode = this.edit_mode_enum.Edit;
      this.display_detail = true;
    },
    view(item) {
      this.edited_item = _.cloneDeep(item);
      this.edit_mode = this.edit_mode_enum.View;
      this.display_detail = true;
    },
    export_excel() {
      console.log("Export select as excel ");
    },
    handleClick(item) {
      console.log("handle click", item);
    },
    close() {
      this.display_detail = false;
      this.edit_mode = edit_mode_enum.View;
      this.edited_item = _.cloneDeep(this.defaultCustomer);
      this.edit_dialog = false;
    },
    getAddress_1(item) {
      if (item.addresses != null && item.addresses.length > 0) {
        return item.addresses[0].address;
      }
      return null;
    },
    getAddress_2(item) {
      if (item.addresses && item.addresses.length > 1) {
        return item.addresses[1].address;
      }
      return null;
    },

    getPhoneByType(item, phone_type) {
      if (item.phones == null) {
        return null;
      }
      for (let i = 0; i < item.phones.length; i++) {
        let phone = item.phones[i];

        if (phone.type == phone_type) {
          return phone.number;
        }
      }
      return null;
    },
    getID(item) {
      if (item.id_full) {
        return item.id_full;
      }
      return item.id_partial;
    },
    onCreateCustomer(item) {
      // this.$swal("Good job!", "新增客戶成功", "success");
      if (this.propagate) {
        this.$emit("create_customer", item);
      } else {
        this.getCustomers();
      }
    },
    onUpdateCustomer(item) {
      // this.$swal("Good job!", "更新客戶成功", "success");
      if(this.propagate){
        this.$emit("update_customer", item);
      }else{
        this.getCustomers();
      }
    },
    updateSelected(item, isSelected) {
      // 當選擇框狀態變更時更新 selected 數組
      if (isSelected) {
        // 如果勾選了，且不在 selected 數組中，則添加
        if (!this.selected.includes(item)) {
          this.selected.push(item);
        }
      } else {
        // 如果取消勾選，從 selected 數組中移除
        const index = this.selected.findIndex(
          (selectedItem) => selectedItem.id === item.id
        );
        if (index !== -1) {
          this.selected.splice(index, 1);
        }
      }
    },
  },
};
</script>
<style scoped>
/* @import "../../../assets/css/jub.css"; */

.download_txt:hover {
  color: blue !important;
  text-decoration: underline;
}
</style>
