var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('customer-detail-dialog',{attrs:{"propEditMode":_vm.edit_mode,"propEditItem":_vm.edited_item,"propDisplay":_vm.display_detail},on:{"close":_vm.close,"create_customer":_vm.onCreateCustomer,"update_customer":_vm.onUpdateCustomer,"refresh":_vm.getCustomers}}),_c('common-loading',{attrs:{"title":_vm.loading_dialog.title,"message":_vm.loading_dialog.message,"loading_dialog":_vm.loading_dialog.dialog,"show_loading":_vm.loading_dialog.show_loading,"show_confirm":_vm.loading_dialog.show_confirm,"confirm_function":_vm.loading_dialog.confirm_function},on:{"confirm":function($event){_vm.loading_dialog.dialog = false}}}),_c('v-card',{staticClass:"card-shadow border-radius-xl"},[_c('div',{staticClass:"card-header-padding"},[_c('h5',{staticClass:"font-weight-bold text-h5 text-typo mb-0"},[_vm._v("客戶資料")])]),_c('v-card-text',{staticClass:"px-0 py-0"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"options":_vm.options,"items":_vm.items,"server-items-length":_vm.items_count,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","show-select":_vm.selectable},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
return [_c('div',{staticClass:"v-data-table__checkbox"},[(item.data_source === '指定')?_c('v-simple-checkbox',{attrs:{"value":isSelected,"ripple":false},on:{"input":function($event){_vm.$set(item, 'selected', $event);
                _vm.updateSelected(item, $event);}}}):_c('span')],1)]}},{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t("Loading..."))+" ")]},proxy:true},{key:"top",fn:function(){return [_vm._t("header"),_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"12","lg":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#cb0c9f","length":_vm.getPageCount(),"circle":"","total-visible":15},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)]},proxy:true},{key:"item.full_name",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.full_name)+" ")]}},{key:"item.id_full",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getID(item))+" ")]}},{key:"item.address_1",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAddress_1(item))+" ")]}},{key:"item.address_2",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getAddress_2(item))+" ")]}},{key:"item.home",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPhoneByType(item, _vm.phone_type_enum.Home))+" ")]}},{key:"item.mobile",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPhoneByType(item, _vm.phone_type_enum.Mobile))+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(_vm.isAllowUpdateCustomer())?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();return _vm.edit(item)}}},on),[_vm._v("mdi-application-edit-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Edit")))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){$event.stopPropagation();return _vm.view(item)}}},on),[_vm._v("mdi-account-search-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("View")))])])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('v-row',[_c('v-col',{staticClass:"ml-auto d-flex justify-end",attrs:{"cols":"12","lg":"6"}},[_c('v-pagination',{staticClass:"pagination",attrs:{"prev-icon":"fa fa-angle-left","next-icon":"fa fa-angle-right","color":"#cb0c9f","length":_vm.getPageCount(),"circle":"","total-visible":15},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }