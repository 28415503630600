<template>
  <v-card class="card-shadow border-radius-xl mt-4 mx-2">
    <div v-for="(item, index) in transactions" :key="index">
      <v-row>
        <v-col>
          <label class="text-md text-grt font-weight-bolder mx-2"> 簽約 </label>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-2 mx-2">
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="填單日期"></common-label>
          <v-menu
            v-model="item.fill_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="px-0"
            :disabled="propEditMode == edit_mode_enum.View"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                outlined
                :class="text_edit_class"
                :value="displayChineseYear(item.fill_date)"
                v-bind="attrs"
                v-on="on"
                :disabled="propEditMode == edit_mode_enum.View"
                clearable
                @click:clear="item.fill_date = null"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="item.fill_date"
              :year-format="formatChineseYear"
              locale="zh-tw"
              @input="item.fill_date_menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="簽約總價"></common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.total_price"
            suffix="萬"
            :disabled="propEditMode == edit_mode_enum.View"
          >
          </v-text-field>
        </v-col>
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="簽約日">簽約日</common-label>
          <v-menu
            v-model="item.signing_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :disabled="propEditMode == edit_mode_enum.View"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                outlined
                :class="text_edit_class"
                :value="displayChineseYear(item.signing_date)"
                v-bind="attrs"
                v-on="on"
                :disabled="propEditMode == edit_mode_enum.View"
                clearable
                @click:clear="item.signing_date = null"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="item.signing_date"
              :year-format="formatChineseYear"
              locale="zh-tw"
              @input="item.signing_date_menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col md="auto" cols="6" class="px-1">
          <common-label label="簽約金額"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.singing_price"
            suffix="萬"
            :disabled="propEditMode == edit_mode_enum.View"
          >
          </v-text-field>
        </v-col>
        <!-- 備証 -->
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="備証"></common-label>
          <v-menu
            v-model="item.proof_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :disabled="propEditMode == edit_mode_enum.View"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                outlined
                class="font-size-input border border-radius-md mt-2"
                :value="displayChineseYear(item.proof_date)"
                v-bind="attrs"
                v-on="on"
                :disabled="propEditMode == edit_mode_enum.View"
                clearable
                @click:clear="item.proof_date = null"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="item.proof_date"
              :year-format="formatChineseYear"
              locale="zh-tw"
              @input="item.proof_date_menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col md="auto" cols="6" class="px-1">
          <common-label label="金額"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.proof_price"
            suffix="萬"
            :disabled="propEditMode == edit_mode_enum.View"
          >
          </v-text-field>
        </v-col>
        <!--完稅  -->
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="完稅"></common-label>
          <v-menu
            v-model="item.tax_payment_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                outlined
                class="font-size-input border border-radius-md mt-2"
                :value="displayChineseYear(item.tax_payment_date)"
                v-bind="attrs"
                v-on="on"
                :disabled="propEditMode == edit_mode_enum.View"
                clearable
                @click:clear="item.tax_payment_date = null"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="item.tax_payment_date"
              :year-format="formatChineseYear"
              locale="zh-tw"
              @input="item.tax_payment_date_menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col md="auto" cols="6" class="px-1">
          <common-label label="金額"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.tax_payment_price"
            suffix="萬"
            :disabled="propEditMode == edit_mode_enum.View"
          >
          </v-text-field>
        </v-col>

        <!-- 付款 -->
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="尾款"></common-label>
          <v-menu
            v-model="item.final_payment_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :disabled="propEditMode == edit_mode_enum.View"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                outlined
                class="font-size-input border border-radius-md mt-2"
                :value="displayChineseYear(item.final_payment_date)"
                v-bind="attrs"
                v-on="on"
                :disabled="propEditMode == edit_mode_enum.View"
                clearable
                @click:clear="item.final_payment_date = null"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="item.final_payment_date"
              :year-format="formatChineseYear"
              locale="zh-tw"
              @input="item.final_payment_date_menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col md="auto" cols="6" class="px-1">
          <common-label label="金額"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.final_payment_price"
            suffix="萬"
            :disabled="propEditMode == edit_mode_enum.View"
          >
          </v-text-field>
        </v-col>
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="結案日期"></common-label>
          <v-menu
            v-model="item.close_date_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :disabled="propEditMode == edit_mode_enum.View"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                hide-details
                outlined
                class="font-size-input border border-radius-md mt-2"
                :value="displayChineseYear(item.close_date)"
                v-bind="attrs"
                v-on="on"
                :disabled="propEditMode == edit_mode_enum.View"
                clearable
                @click:clear="item.close_date = null"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="item.close_date"
              :year-format="formatChineseYear"
              locale="zh-tw"
              @input="item.close_date_menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <!-- 佣金加項 -->
      <v-row>
        <v-col>
          <label class="text-md text-grt font-weight-bolder mx-2">
            佣金加項
          </label>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-2 mx-2">
        <!-- <v-col md="2" cols="12" align-self="center">
          <common-label label="佣金加項"> </common-label>
        </v-col> -->
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="賣方"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.addition_sale"
            type="number"
            suffix="元"
            :disabled="propEditMode == edit_mode_enum.View"
            @change="getAdditionSummary(item)"
          >
          </v-text-field>
        </v-col>
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="買方"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.addition_buy"
            type="number"
            suffix="元"
            :disabled="propEditMode == edit_mode_enum.View"
            @change="getAdditionSummary(item)"
          >
          </v-text-field>
        </v-col>
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="其他"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.addition_other"
            type="number"
            suffix="元"
            :disabled="propEditMode == edit_mode_enum.View"
            @change="getAdditionSummary(item)"
          >
          </v-text-field>
        </v-col>
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="小計"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            :value="item.addition_summary"
            readonly
            type="number"
            suffix="元"
            :disabled="propEditMode == edit_mode_enum.View"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- 佣金減項 -->
      <v-row>
        <v-col>
          <label class="text-md text-grt font-weight-bolder mx-2">
            佣金減項(代收/代付)
          </label>
        </v-col>
      </v-row>
      <v-row no-gutters class="my-2 mx-2">
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="中人費"></common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.deduction_intermediary_fee"
            type="number"
            suffix="元"
            :disabled="propEditMode == edit_mode_enum.View"
            @change="getDeductionSummary(item)"
          >
          </v-text-field>
        </v-col>
        <v-col md="auto" cols="6" class="px-1">
          <common-label label="代書費"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.deduction_agency_fee"
            type="number"
            suffix="元"
            :disabled="propEditMode == edit_mode_enum.View"
            @change="getDeductionSummary(item)"
          >
          </v-text-field>
        </v-col>
        <v-col
          md="4"
          cols="12"
          class="px-1"
          v-for="(deduction, index) in item.deduction_other_array"
          :key="index"
        >
          <div class="d-flex">
            <common-label label="其他" v-show="index == 0"> </common-label>
            <v-spacer></v-spacer>
            <v-btn
              icon
              x-small
              @click="remove_deduction_other(item, index)"
              v-if="propEditMode != edit_mode_enum.View"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
            <v-btn
              icon
              x-small
              @click="add_deduction_other(item)"
              v-if="
                propEditMode != edit_mode_enum.View &&
                index == item.deduction_other_array.length - 1
              "
            >
              <v-icon color="red">mdi-plus</v-icon>
            </v-btn>
          </div>
          <v-row no-gutters class="justify-space-around">
            <v-col cols="auto" align-self="center">
              <label class="text-sm font-weight-bolder ms-1 mx-2">名稱</label>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                hide-details
                outlined
                :class="text_edit_class"
                v-model="deduction.name"
                :disabled="propEditMode == edit_mode_enum.View"
              >
              </v-text-field
            ></v-col>
            <v-col cols="auto" align-self="center" class="text-center">
              <label class="text-sm font-weight-bolder ms-1">金額</label>
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                outlined
                :class="text_edit_class"
                v-model="deduction.value"
                type="number"
                suffix="元"
                @change="getDeductionSummary(item)"
                :disabled="propEditMode == edit_mode_enum.View"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="auto" cols="6" class="px-2">
          <common-label label="小計"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            :value="item.deduction_summary"
            readonly
            type="number"
            suffix="元"
            :disabled="propEditMode == edit_mode_enum.View"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- other -->
      <v-row no-gutters class="my-2 mx-2">
        <v-col md="auto" cols="12" class="px-1">
          <common-label label="佣金總計"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.commission"
            suffix="元"
            :disabled="propEditMode == edit_mode_enum.View"
          >
          </v-text-field>
        </v-col>
        <v-col md="auto" cols="12" class="px-1">
          <common-label label="發票或收據"> </common-label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="item.invoice"
            :disabled="propEditMode == edit_mode_enum.View"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- 買方 -->
      <v-row v-if="showBuyer">
        <v-col>
          <label class="text-md text-grt font-weight-bolder mx-2"> 買方 </label>
        </v-col></v-row
      >
      <v-row
        v-if="showBuyer"
        class="mx-2"
        v-for="(agent, a_ind) in buyers"
        :key="a_ind + 2000"
      >
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">姓名</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.name"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">電話</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.phone"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">手機</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.mobile"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="2" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">身份證</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.id_full"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="3" cols="12" class="py-0">
          <label class="font-weight-bolder ms-1">聯絡地址</label>
          <v-text-field
            hide-details
            outlined
            :class="text_edit_class"
            v-model="agent.address"
            :disabled="readOnly"
          >
          </v-text-field>
        </v-col>
        <v-col md="1" cols="12" class="py-0" align-self="end" v-if="!readOnly">
          <v-btn icon v-if="a_ind == buyers.length - 1" @click="addBuyer">
            <v-icon color="primary">mdi-plus-circle</v-icon>
          </v-btn>
          <v-btn
            icon
            v-if="agent.id == null && buyers.length > 1"
            @click="removeBuyer(a_ind)"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- 交易人員 -->
      <v-row>
        <v-col>
          <label class="text-md text-grt font-weight-bolder mx-2"> 業績 </label>
        </v-col>
      </v-row>
      <v-row no-gutters class="py-2">
        <v-col cols="12"
          ><v-data-table
            :headers="sale_headers"
            :options.sync="sale_options"
            :items="sales"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.last_name="{ item }">
              {{ item.user.last_name }}{{ item.user.first_name }}
            </template>
            <template v-slot:item.type="{ item }">
              <div>
                {{ item.business_type | getText(business_type_str) }}
              </div>
            </template>
            <template v-slot:item.performance="{ item }">
              <v-text-field
                hide-details
                outlined
                :class="text_edit_class"
                v-model="item.performance"
                :disabled="propEditMode == edit_mode_enum.View"
                @change="onPerformanceChange(item)"
              >
              </v-text-field>
            </template> </v-data-table
        ></v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
import {
  case_related_type,
  inquire_headers,
  transaction_headers,
  business_type_str,
  related_person_type_enum,
  business_type_enum,
} from "@/case.js";
import HttpCommonMixin from "@/components/CRM/HttpCommonMixin.vue";
import { defaultCustomer } from "@/customers.js";
import { edit_mode_enum, common_text_edit_class } from "@/definitions.js";
import CommonUtility from "@/util/CommonUtility.js";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import CustomerBase from "./CustomerBase.vue";
import CommonLabel from "@/components/CRM/CommonLabel.vue";
import { mapGetters } from "vuex";
import _ from "lodash";

Vue.use(VueSweetalert2);
export default {
  name: "case-transactions",
  props: {
    propInfo: {
      type: Array,
      default: () => [],
    },
    propSales: {
      type: Array,
      default: () => [],
    },
    propType: {
      type: Number,
      default: 0,
    },
    propCaseName: {
      type: String,
      default: "",
    },
    propCaseType: {
      type: String,
      default: "",
    },
    propEditMode: {
      type: Number,
    },
    propRelatedPeople: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [CustomerBase, HttpCommonMixin],
  components: {
    CommonLabel,
  },
  computed: {
    readOnly() {
      return this.propEditMode == edit_mode_enum.View;
    },
    showBuyer() {
      // iterator the sales if they business type is 1
      if (this.isAllowViewAllTransactionCase()) {
        return true;
      }
      return this.sales.some(
        (sale) => sale.business_type == business_type_enum.Sale
      );
    },
  },
  data() {
    return {
      title: "交易紀錄",
      add_title: "交易",
      case_related_type: case_related_type,
      inquire_headers: inquire_headers,
      transaction_headers: transaction_headers,
      business_type_str: business_type_str,
      related_person_type_enum: related_person_type_enum,
      business_type_enum: business_type_enum,
      selected_customer: [],
      customerInfo: {
        full_name: null,
        full_id: null,
        company: null,
        address: null,
        phone: null,
      },
      // edit
      edit_mode_enum: edit_mode_enum,
      edit_customer_mode: edit_mode_enum.View,
      defaultCustomer: defaultCustomer,
      edited_customer_item: {}, // for customer
      transactions: [],
      original_transactions: [],
      related_info_count: 0,
      sales: [],
      sale_headers: [
        {
          text: "姓名",
          cellClass: "border-bottom",
          sortable: false,
          value: "last_name",
          width: "10%",
          class: "font-weight-bolder border-bottom",
        },
        {
          text: "手機",
          cellClass: "border-bottom",
          sortable: false,
          width: "10%",
          value: "user.mobile_phone",
          class: "font-weight-bolder border-bottom",
        },
        {
          text: "Email",
          cellClass: "border-bottom",
          sortable: false,
          width: "10%",
          value: "user.email",
          class: "font-weight-bolder border-bottom",
        },
        {
          text: "業務類型",
          cellClass: "border-bottom",
          sortable: false,
          width: "10%",
          value: "type",
          class: "font-weight-bolder border-bottom",
        },
        {
          text: "業績",
          cellClass: "border-bottom",
          sortable: false,
          value: "performance",
          width: "40%",
          class: "font-weight-bolder border-bottom",
        },
      ],
      sale_options: {},
      text_edit_class: common_text_edit_class,
      init: false,
      // debug
      debug: true,
      buyers: [
        {
          id: null,
          type: related_person_type_enum.Buyer,
          name: null,
          phone: null,
          mobile: null,
          address: null,
          id_full: null,
        },
      ],
    };
  },
  mounted() {
    this.parseRelatedInfo(this.propInfo);
    this.parseSaleInfo(this.propSales);
    this.parseBuyerInfo(this.propRelatedPeople);
    console.log("pares ", this.propRelatedPeople);
  },
  methods: {
    ...mapGetters(["isAllowViewAllTransactionCase"]),
    parseRelatedInfo(info) {
      if (this.init == true) {
        return;
      }
      this.init = true;
      this.print_log("parseRelatedInfo", info);
      if (info == null || info.length == 0) {
        this.transactions = [];
        this.transactions.push({
          fill_date: null,
          total_price: null,
          signing_date: null,
          signing_price: null,
          proof_date: null,
          proof_price: null,
          tax_payment_date: null,
          tax_payment_price: null,
          final_payment_date: null,
          final_payment_price: null,
          close_date: null,
          // 佣金加項
          addition_sale: null,
          addition_buy: null,
          addition_other: null,
          addition_summary: null,
          // 佣金減項
          deduction_intermediary_fee: null,
          deduction_agency_fee: null,
          deduction_other: null,
          deduction_summary: null,
          commission: null,
          invoice: null,
          fill_date_menu: false,
          signing_date_menu: false,
          proof_date_menu: false,
          tax_payment_date_menu: false,
          final_payment_date_menu: false,
          close_date_menu: false,
          deduction_other_array: [{ name: "其他", value: null }],
        });
        this.original_transactions = _.cloneDeep(this.transactions);
        return;
      }

      info.forEach((item) => {
        item.fill_date_menu = false;
        item.signing_date_menu = false;
        item.proof_date_menu = false;
        item.tax_payment_date_menu = false;
        item.final_payment_date_menu = false;
        item.deduction_other_array = this.coverToObject(item.deduction_other);
        item.addition_summary = this.getAdditionSummary(item);
        item.deduction_summary = this.getDeductionSummary(item);
      });

      this.transactions = _.cloneDeep(info);
      this.original_transactions = _.cloneDeep(this.transactions);
      this.related_info_count = this.transactions.length;
    },
    parseSaleInfo(info) {
      // console.log("parseSaleInfo", info);
      this.sales = _.cloneDeep(info);
    },
    parseBuyerInfo(info) {
      if (info != null) {
        let buyers = [];
        info.forEach((item) => {
          if (item.type == related_person_type_enum.Buyer) {
            buyers.push(item);
          }
        });
        if (buyers.length > 0) {
          this.buyers = buyers;
        }
      }
    },
    coverToObject(value) {
      if (value) {
        let ret = JSON.parse(value);
        return ret;
      }
      return [{ name: "其他", value: null }];
    },
    getAdditionSummary(item) {
      item.addition_summary = 0;
      const additionSale = parseFloat(item.addition_sale);
      const additionBuy = parseFloat(item.addition_buy);
      const additionOther = parseFloat(item.addition_other);

      if (!isNaN(additionSale)) {
        item.addition_summary += additionSale;
      }
      if (!isNaN(additionBuy)) {
        item.addition_summary += additionBuy;
      }
      if (!isNaN(additionOther)) {
        item.addition_summary += additionOther;
      }
      return item.addition_summary;
    },

    getDeductionSummary(item) {
      item.deduction_summary = 0;
      const deductionIntermediaryFee = parseFloat(
        item.deduction_intermediary_fee
      );
      const deductionAgencyFee = parseFloat(item.deduction_agency_fee);

      let deductionOtherTotal = 0;
      item.deduction_other_array.forEach((deduction) => {
        const deductionValue = parseFloat(deduction.value);
        if (!isNaN(deductionValue)) {
          deductionOtherTotal += deductionValue;
        }
      });
      if (!isNaN(deductionIntermediaryFee)) {
        item.deduction_summary += deductionIntermediaryFee;
      }
      if (!isNaN(deductionAgencyFee)) {
        item.deduction_summary += deductionAgencyFee;
      }
      if (!isNaN(deductionOtherTotal)) {
        item.deduction_summary += deductionOtherTotal;
      }
      return item.deduction_summary;
    },
    resetSearch() {
      this.customerInfo = {
        full_name: null,
        full_id: null,
        company: null,
        address: null,
        phone: null,
      };
      this.items = [];
      this.items_count = 0;
    },
    searchCustomers() {
      this.getCustomerByCondition(this.customerInfo);
    },
    close() {
      this.edit_customer_mode = this.edit_mode_enum.View;
      this.print_log("close edit mode");
    },
    getDeductionInfo(item) {
      //  cover deduction_others array into string
      if (
        item.deduction_other_array == null ||
        item.deduction_other_array.length == 0
      ) {
        return null;
      }
      return JSON.stringify(item.deduction_other_array);
    },
    getRelatedInfo() {
      let info = _.cloneDeep(this.transactions);
      // compare to original_transactions if not change, return null
      if (_.isEqual(info, this.original_transactions)) {
        return [];
      }
      this.print_log("get related info", info);
      for (let key in info) {
        if (info[key] === "") {
          info[key] = null;
        }
      }
      return info;
    },
    print_log(...args) {
      if (this.debug) {
        console.log("[", this.title, "]", ...args);
      }
    },
    clear() {
      this.print_log("clear case related info");
      this.items = [];
      this.items_count = 0;
      this.selected_customer = [];
      this.transactions = [];
      this.related_info_count = 0;
      this.init = false;
      this.buyers = [
        {
          id: null,
          type: related_person_type_enum.Buyer,
          name: null,
          phone: null,
          mobile: null,
          address: null,
          id_full: null,
        },
      ];
    },
    formatChineseYear(val) {
      return CommonUtility.formatChineseYear(val);
    },
    displayChineseYear(val) {
      return CommonUtility.displayChineseYear(val);
    },
    // deduction_others
    add_deduction_other(item) {
      item.deduction_other_array.push({
        name: "其他",
        value: null,
      });
    },
    remove_deduction_other(item, index) {
      item.deduction_other_array.splice(index, 1);
      this.getDeductionSummary(item);
    },
    onPerformanceChange(item) {
      this.$emit("onPerformanceChange", item);
    },
    addBuyer() {
      this.buyers.push({
        id: null,
        type: related_person_type_enum.Buyer,
        name: null,
        phone: null,
        mobile: null,
        address: null,
        id_full: null,
      });
    },
    removeBuyer(index) {
      if (this.buyers.length == 1) {
        return;
      }
      this.buyers.splice(index, 1);
    },
    getLegalInfo(info) {
      // filter the name is not null or empty in info array
      return info.filter((item) => item.name != null && item.name != "");
    },
    getBuyers() {
      return this.getLegalInfo(this.buyers);
    },
  },
};
</script>

<style scoped>
/deep/ tr.v-data-table__selected {
  background: #7d92f5 !important;
}
</style>
